import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import filter from 'lodash/filter'
import { Button, Avatar, Image, Dropdown, Menu } from 'antd'
import expandIcon from '@/assets/svg/expand-icon.svg'
import userIcon from '@/assets/svg/menu/user.svg'
import helpIcon from '@/assets/svg/menu/help.svg'
import logOutIcon from '@/assets/svg/menu/log-out.svg'
import { MoreOutlined } from '@ant-design/icons'
import navigations, { NavigationItemType } from '@/config/navigations'
import { UserType } from '@/redux/user/types'
import { logout } from '@/views/Login/ducks/actions'
import constants from '@/config/constants'
import { userRolesForDisplay } from '@/utils/helpers'

interface NavigationProps {
  user: UserType | null
  activePath: string
  collapsed: boolean
  setCollapsed: any
}

const Navigation: React.FunctionComponent<NavigationProps> = ({
  user,
  activePath,
  collapsed,
  setCollapsed,
}: NavigationProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const topNavItems = filter(navigations, { position: 'top' })
  const bottomNavItems = filter(navigations, { position: 'bottom' })
  const avatar = useMemo(() => user?.image ?? '', [user?.image])
  const locationData: any = user?.available_locations

  const renderNavItem = (items: NavigationItemType[]) =>
    items.map(({ name, label, path, icon: Icon, permission, permissionName }) => {
      const permissionRoute = user?.permission?.find((item) => item.name === permissionName)

      if (permissionRoute && !permissionRoute?.is_checked) {
        return null
      }

      if (user?.role === 'Admin' && path === '/manager/users') {
        return null
      }
      // if (
      //   activePath.startsWith(path) &&
      //   !permissionRoute?.is_checked &&
      //   !user?.role?.toLowerCase()?.includes('admin')
      // ) {
      //   return null
      // }

      if (permissionRoute?.is_checked) {
        const isActive = activePath.startsWith(path)
        return (
          <div
            key={name}
            onClick={() => history.push(path)}
            aria-hidden="true"
            className={isActive ? 'nav_li active' : 'nav_li'}
          >
            <Icon className="" active={isActive} />
            <span className="nav_label">{label}</span>
          </div>
        )
      }
      if (
        (user?.role === 'Admin' || user?.user_type === 'maitre_admin') &&
        permission?.includes(constants.ROLE_ADMIN) &&
        window.location.pathname.includes('admin')
      ) {
        const isActive = activePath.startsWith(path)
        return (
          <div
            key={name}
            onClick={() => history.push(path)}
            aria-hidden="true"
            className={isActive ? 'nav_li active' : 'nav_li'}
          >
            <Icon className="" active={isActive} />
            <span className="nav_label">{label}</span>
          </div>
        )
      }
      if (
        (user?.role === 'Admin' || user?.user_type === 'maitre_admin') &&
        !permission?.includes(constants.ROLE_ADMIN) &&
        !window.location.pathname.includes('admin')
      ) {
        const isActive = activePath.startsWith(path)
        return (
          <div
            key={name}
            onClick={() => history.push(path)}
            aria-hidden="true"
            className={isActive ? 'nav_li active' : 'nav_li'}
          >
            <Icon className="" active={isActive} />
            <span className="nav_label">{label}</span>
          </div>
        )
      }

      if (
        (user?.role === 'location_admin' || user?.user_type === 'location_admin') &&
        !permission?.includes(constants.ROLE_ADMIN)
      ) {
        const isActive = activePath.startsWith(path)
        return (
          <div
            key={name}
            onClick={() => history.push(path)}
            aria-hidden="true"
            className={isActive ? 'nav_li active' : 'nav_li'}
          >
            <Icon className="" active={isActive} />
            <span className="nav_label">{label}</span>
          </div>
        )
      }

      if (
        !user?.role?.toLowerCase()?.includes('admin') &&
        user?.permission?.length === 0 &&
        !permission?.includes(constants.ROLE_ADMIN)
      ) {
        const isActive = activePath.startsWith(path)
        return (
          <div
            key={name}
            onClick={() => history.push(path)}
            aria-hidden="true"
            className={isActive ? 'nav_li active' : 'nav_li'}
          >
            <Icon className="" active={isActive} />
            <span className="nav_label">{label}</span>
          </div>
        )
      }

      return null
    })

  const handleLogout = (): void => {
    dispatch(logout())
  }

  const getInitials = (name: string) => {
    const words = name?.split(' ')
    const initials = words.map((word) => word?.charAt(0)).join('')
    return initials.toUpperCase()
  }

  const menuKabab = (
    <Menu
      className="sidebar_menu"
      items={[
        {
          key: '1',
          title: '',
          label: (
            <div className="user_details">
              {avatar ? (
                <Avatar src={avatar} className="avatar" size={40} />
              ) : user?.name ? (
                <Avatar size={40}>{getInitials(user?.name)}</Avatar>
              ) : (
                ''
              )}
              <div className="user_info">
                <h3>{user?.name}</h3>
                <p>{userRolesForDisplay(user?.role as string)}</p>
              </div>
            </div>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '2',
          title: '',
          label: (
            <Button className="sidebar_button" onClick={() => history.push('/account')}>
              <Image src={userIcon} width={20} preview={false} />
              <div className="menu_label">Account Settings</div>
            </Button>
          ),
        },
        // {
        //   key: '3',
        //   title: '',
        //   label: (
        //     <Button className="sidebar_button">
        //       <Image src={bellIcon} width={20} preview={false} />
        //       <div className="menu_label">Notification Centre</div>
        //     </Button>
        //   ),
        // },
        {
          key: '4',
          title: '',
          label: (
            <Button className="sidebar_button" onClick={() => history.push('/help-center')}>
              <Image src={helpIcon} width={20} preview={false} />
              <div className="menu_label">Help Center</div>
            </Button>
          ),
        },
        {
          key: '5',
          title: '',
          label: (
            <Button className="sidebar_button" onClick={handleLogout}>
              <Image src={logOutIcon} width={20} preview={false} />
              <div className="menu_label">Log out</div>
            </Button>
          ),
        },
      ]}
    />
  )

  const menuKababAdmin = (
    <Menu
      className="sidebar_menu"
      items={[
        {
          key: '1',
          title: '',
          label: (
            <div className="user_details">
              {avatar ? (
                <Avatar src={avatar} className="avatar" size={40} />
              ) : user?.name ? (
                <Avatar size={40}>{getInitials(user?.name)}</Avatar>
              ) : (
                ''
              )}
              <div className="user_info">
                <h3>{user?.email}</h3>
                <p>{user?.role}</p>
              </div>
            </div>
          ),
        },
        {
          type: 'divider',
        },

        {
          key: '2',
          title: '',
          label: (
            <Button className="sidebar_button" onClick={handleLogout}>
              <Image src={logOutIcon} width={20} preview={false} />
              <div className="menu_label">Log out</div>
            </Button>
          ),
        },
      ]}
    />
  )

  const collapsable = () => {
    localStorage.setItem('collapsable', JSON.stringify(!collapsed))
    setCollapsed(!collapsed)
  }

  return (
    <>
      {user?.stack !== 'staff' && user?.stack !== 'attendant' && (
        <div
          className={
            user?.hotel?.name && !window.location.pathname.includes('admin')
              ? 'navigation'
              : 'navigation nav_without_hotel'
          }
        >
          <div className="topNav">{renderNavItem(topNavItems)}</div>

          <div className="bottomNav">
            {renderNavItem(bottomNavItems)}
            {/* {multiLocations && (user?.role !== 'Admin' || user?.user_type !== 'maitre_admin') && ( */}
            {/* )} */}
          </div>
        </div>
      )}

      <div className="navItemLink">
        <div className="user_details">
          <Dropdown
            overlay={window.location.pathname.includes('admin') ? menuKababAdmin : menuKabab}
            trigger={['click']}
            placement="top"
            overlayClassName={collapsed ? 'side_dropdown side_drop_collapsed' : 'side_dropdown'}
          >
            <div className="sidebar_menu_open">
              {avatar ? (
                <Avatar src={avatar} className="avatar" size={40} />
              ) : user?.name ? (
                <Avatar size={40}>{getInitials(user?.name)}</Avatar>
              ) : (
                ''
              )}
              <div className="user_info">
                <h3>{user?.name ?? user?.email}</h3>
                <p>{userRolesForDisplay(user?.role ?? '')}</p>
              </div>
              {/* <div className="more_options">
                <MoreOutlined />
              </div> */}
            </div>
          </Dropdown>
          <div className="user_btn_options">
            <div className="expand_icon">
              <Button type="link" onClick={collapsable}>
                <Image preview={false} src={expandIcon} width={16} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navigation
