import axios from 'axios'
import store from '@/redux/store'
import { logout } from '@/views/Login/ducks/actions'
import { BASE_URLS, ENVIRONMENT_MODE } from './constants'

const baseUrl = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_TASK_URL

const authInterceptors = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Content-Type': 'application/json',
  },
})

authInterceptors.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem('@o-auth/authToken')
    const jsonToken = localStorage.getItem('@maitretoken')
    if (jsonToken) {
      const parsedToken: { refreshToken: string; accessToken: string } = JSON.parse(jsonToken)

      // const refreshToken = token?.refreshToken
      // if (token) {
      config.headers.Authorization = `Bearer ${parsedToken.accessToken}`
      config.headers.Accept = 'application/x-www-form-urlencoded;charset=UTF-8'
      // }
    }
    return config
  },
  (error) => Promise.reject(error),
)

authInterceptors.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    console.log('error', error?.response)
    const originalRequest = error.config
    console.log('error?.response', error?.response)
    // eslint-disable-next-line no-underscore-dangle
    if (error?.response?.status === 401 && !originalRequest._dretry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true
      const jsonToken = localStorage.getItem('@maitretoken')

      if (jsonToken) {
        const parsedToken: { refreshToken: string; accessToken: string } = JSON.parse(jsonToken)

        const postData = {
          refreshToken: parsedToken.refreshToken,
        }
        authInterceptors
          .post(`${baseUrl}refresh`, postData, {
            headers: {
              'content-Type': 'application/json',
            },
          })
          .then(async (res) => {
            if (res.data.accessToken) {
              const val = {
                accessToken: res.data.accessToken,

                refreshToken: res.data.refreshToken,
              }
              localStorage.setItem('@maitretoken', JSON.stringify(val))
              originalRequest.headers.Authorization = `Bearer ${
                JSON.parse(localStorage.getItem('@maitretoken') as string).accessToken
              }`
              return axios(originalRequest)
            }
            return undefined
          })
          .catch(() => {
            localStorage.removeItem('@maitretoken')
            localStorage.clear()
            setTimeout(() => {
              window.location.assign('/auth')
            }, 500)
          })
      }
    } else if (error?.response?.status === 401) {
      // onLogout();
      // store.dispatch(setSignedIn(false));
      localStorage.removeItem('@maitretoken')
      localStorage.clear()
      setTimeout(() => {
        window.location.assign('/auth')
      }, 500)
    } else if (error?.response?.status === 403) {
      localStorage.removeItem('@maitretoken')
      localStorage.clear()
      setTimeout(() => {
        window.location.assign('/auth')
      }, 500)
    }

    // if (error?.response && error?.response?.status === 401) {
    //   localStorage.clear()
    //   setTimeout(() => {
    //     window.location.assign('/')
    //   }, 500)
    // }
    return Promise.reject(error)
  },
)
export default authInterceptors
