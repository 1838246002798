import React, { Fragment } from 'react'
import * as action_types from './constants'

interface DataType {
  key: string
  area_name: string
  index: number
}

export interface AdminLocationStateType {
  locationsListing: []
  locationsData: {
    loading: boolean
    locationArray: DataType[]
  }
  hotelSectionData: {
    loading: boolean
    sectionArray: []
  }
  requestItems: []
  deliveryServiceListData: []
  hotelRolePermission: {
    templates: {
      department_name: string
      role_name: string
    }[]
    permissions: {
      [key: string]: {
        title: string
        description: any
        template_permissions: {
          id: string
          role_name: string
          is_changeable: boolean
          access_level: number
          is_checked: boolean
          show_access_selector: boolean
          is_disabled: boolean
        }[]
      }[]
    }
  }
  schedulingUsers: []
  ukgAccountList: {
    ukg: []
    homebase: []
  }
  schedulersData: {
    ukg: {
      credential_id: string
      credential_type: string
      hotel_id: string
      location_name: string
      id: string
    }
    homebase: {
      credential_id: string
      credential_type: string
      hotel_id: string
      location_name: string
      id: string
    }
  }
  ukgSchedulingData: {
    credential_id: string
    credential_type: string
    hotel_id: string
    id: string
    location_name: string
  }
  ukgCredentialsData: {
    ukg_id: string
    username: string
    credential_name: string
    password: string
    api_key: string
    company_id: string
    shared_users: string[]
  }
  hotelPMSData: {
    PMS_NAME: string
    RESORT_ID: string
    USERNAME: string
    PASSWORD: string
    SSID_URL: string
    PROPERTY_CODE: string
    CHAIN_CODE: string
    OPERA_AUTH_KEY: string
    OPERA_APP_KEY: string
    OPERA_LOGIN: string
    OPERA_PASSWORD: string
    HOTEL_ID: string
    STAYNTOUCH_CLIENT_ID: string
    STAYNTOUCH_CLIENT_SECRET: string
    STAYNTOUCH_REDIRECT_URI: string
    STAYNTOUCH_RESPONSE_TYPE: string
    STAYNTOUCH_GRANT_TYPE: string
    OHIP_STATUS: string
    OP5_STATUS: string
    STAYNTOUCH_STATUS: string
    MEWS_STATUS: string
    HOTSOFT_STATUS: string
  }
  getHotelLayout: {
    floorName: string
    index: number
    isDeleted: boolean
    rooms: {
      credits: number
      desc: string
      index: number
      isDeleted: boolean
      name: string
      sectionId: string
      type: string
      isDoubleRoom: boolean
    }[]
  }[]
  taskPreferencedata: {
    TASK_DISPLAY_PREF: string
    SPECIFIC_TIME: string
  }
  sectionDropData: []
  hotelFilters: {
    hotelGroup: []
    mgmtCompany: []
  }
  hotelDetail: {
    name: string
    address: string
    city: string
    state: string
    zip: string
    country: string
    timezone_area: string
    group: string
    mgmt_company: string
    image: string
  }
  getRoomService: {
    allocation_method: string
    cleaning_credit_per_RA: string
    isTurndownEnabled: string
    linen_change_duration: string
    turndown_credit_per_RA: string
    allocation_section: string
    is_linen_enabled: string
    reset_time: string | any
    cancel_cleaning_time: number
  }
  getHotelSettings: {
    token_expiry_enabled: boolean
    token_expiry_time: number
  }
  hotelPMSListData: {
    currentlySetPMS: {
      [scheduling_type: string]: {
        id: string
        hotel_id: string
        credential_id: string
        location_name: string
        credential_name: string
        status: boolean
      }
    }
    defaultShiftDuration: number | undefined
    isRoleSelectionAllowed: boolean | undefined
    credentialList: {
      label: string
      value: string
    }[]
  }
}

const initialState = {
  locationsListing: [],
  locationsData: {
    loading: false,
    locationArray: [],
  },
  hotelSectionData: {
    loading: false,
    sectionArray: [],
  },
  deliveryServiceListData: [],
  hotelRolePermission: {
    templates: [],
    permissions: {},
  },
  schedulingUsers: [],
  ukgAccountList: {
    ukg: [],
    homebase: [],
  },
  ukgCredentialsData: {},
  ukgSchedulingData: {},
  schedulersData: [],
  hotelPMSData: {},
  getHotelLayout: [],
  taskPreferencedata: {},
  sectionDropData: [],
  hotelFilters: {
    hotelGroup: [],
    mgmtCompany: [],
  },
  requestItems: [],
  hotelDetail: {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    timezone_area: '',
    group: '',
    mgmt_company: '',
    image: '',
  },
  getRoomService: {
    allocation_method: '',
    cleaning_credit_per_RA: '',
    isTurndownEnabled: '',
    linen_change_duration: '',
    turndown_credit_per_RA: '',
  },
  getHotelSettings: {
    token_expiry_enabled: '',
    token_expiry_time: '',
  },
  hotelPMSListData: {
    currentlySetPMS: {},
    defaultShiftDuration: 0,
    isRoleSelectionAllowed: false,
    credentialList: {},
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.SET_STATE:
      return {
        ...state,
        locationsData: { ...action.payload },
      }

    case action_types.SET_STATE_HOTEL:
      return {
        ...state,
        hotelSectionData: { ...action.payload },
      }

    case action_types.LOCATION_LISTINGS:
      return { ...state, locationsListing: data }

    case action_types.GET_HOTEL_LOCATIONS:
      return { ...state, locationsData: { locationArray: data } }

    case action_types.HOTEL_ROLE_PERMISSION:
      return { ...state, hotelRolePermission: data }

    case action_types.SCHEDULING_USERS:
      return { ...state, schedulingUsers: data }

    case action_types.UKG_ACCOUNT_LIST:
      return { ...state, ukgAccountList: data }

    case action_types.UKG_CREDENTIALS_DATA:
      return { ...state, ukgCredentialsData: data }

    case action_types.UKG_SCHEDULING_DATA:
      return { ...state, ukgSchedulingData: data }

    case action_types.SCHEDULERS_DATA:
      return { ...state, schedulersData: data }

    case action_types.HOTEL_PMS:
      return { ...state, hotelPMSData: data }

    case action_types.GET_HOTEL_SECTIONS:
      return { ...state, hotelSectionData: { sectionArray: data } }

    case action_types.HOTEL_LAYOUT:
      return { ...state, getHotelLayout: data }

    case action_types.TASKS_PREFERENCE:
      return { ...state, taskPreferencedata: data }

    case action_types.SECTION_DROPDOWN:
      return { ...state, sectionDropData: data }

    case action_types.HOTEL_FILTERS:
      return { ...state, hotelFilters: data }

    case action_types.REQUEST_ITEMS:
      return { ...state, requestItems: data }

    case action_types.HOTEL_DETAIL:
      return { ...state, hotelDetail: data }

    case action_types.DELIVERY_SERVICE_LIST:
      return { ...state, deliveryServiceListData: data }

    case action_types.DELIVERY_SERVICE_POP:
      return { ...state, deliveryServicePopData: data }

    case action_types.ROOM_SERVICE:
      return { ...state, getRoomService: data }

    case action_types.HOTEL_SETTINGS:
      return { ...state, getHotelSettings: data }

    case action_types.HOTEL_PMS_DATA:
      return { ...state, hotelPMSListData: data }

    default:
      return state
  }
}
