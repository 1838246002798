export const LOCATION_LISTINGS = 'LOCATION_LISTINGS'
export const GET_HOTEL_LOCATIONS = 'GET_HOTEL_LOCATIONS'
export const HOTEL_ROLE_PERMISSION = 'HOTEL_ROLE_PERMISSION'
export const SCHEDULING_USERS = 'SCHEDULING_USERS'
export const UKG_ACCOUNT_LIST = 'UKG_ACCOUNT_LIST'
export const UKG_CREDENTIALS_DATA = 'UKG_CREDENTIALS_DATA'
export const UKG_SCHEDULING_DATA = 'UKG_SCHEDULING_DATA'
export const SCHEDULERS_DATA = 'SCHEDULERS_DATA'
export const HOTEL_PMS = 'HOTEL_PMS'
export const GET_HOTEL_SECTIONS = 'GET_HOTEL_SECTIONS'
export const HOTEL_LAYOUT = 'HOTEL_LAYOUT'
export const TASKS_PREFERENCE = 'TASKS_PREFERENCE'
export const SECTION_DROPDOWN = 'SECTION_DROPDOWN'
export const HOTEL_FILTERS = 'HOTEL_FILTERS'
export const REQUEST_ITEMS = 'REQUEST_ITEMS'
export const HOTEL_DETAIL = 'HOTEL_DETAIL'
export const DELIVERY_SERVICE_LIST = 'DELIVERY_SERVICE_LIST'
export const DELIVERY_SERVICE_POP = 'DELIVERY_SERVICE_POP'
export const ROOM_SERVICE = 'ROOM_SERVICE'
export const SET_STATE = 'SET_STATE'
export const SET_STATE_HOTEL = 'SET_STATE_HOTEL'
export const HOTEL_PMS_DATA = 'HOTEL_PMS_DATA'
export const HOTEL_SETTINGS = 'HOTEL_SETTINGS'
